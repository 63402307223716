<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmblogFormData && Object.keys(vmblogFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationblog_title">{{cvBlogIdLabel}}</label>
                  <input
                    v-model="vmblogFormData.blog_id"
                    type="text"
                    class="form-control"
                    required
                  />
                  <span class="primary-color"><small>E.g. BLOGa3b91a1e1a5247cf92c676f9b6ecb085, BLOGAPPTESTPREPSAT0000001, BLOGGPATHPROD01</small></span>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationblog_title">{{cvBlogTitleLabel}} <span class="primary-color font-size-16">*</span></label>
                  <input
                    v-model="vmblogFormData.blog_title"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationblog_video">{{cvBlogVideoLabel}} </label>
                  <input v-model="vmblogFormData.blog_video" type="text" class="form-control" required/>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationblog_desc">{{cvBlogDescLabel}} <span class="primary-color font-size-16">*</span></label>
                  <vue-editor v-model="vmblogFormData.blog_desc"></vue-editor>
                </div>

                <!-- Blog Category -->
                <div class="col-md-12 mb-3" :key="Update">
                  <label for="validationblog_category">{{ cvBlogCateLabel }} <span class="primary-color font-size-16">*</span></label>
                  <select v-model="vmblogFormData.blog_category" class="form-control">
                    <option :value="null" disabled>Choose Blog Category</option>
                    <option  v-for="(category, index) of blogFiltersList['blog_category']" :key="('blog_category'+index+1)" :value="category.blog_category">{{ category.blog_category }}</option>
                    <option value="AddNew">Add New</option>
                  </select>
                  <div v-if="vmblogFormData.blog_category ==='AddNew'" style="margin-top: 5px" >
                    <input v-model="vmblogFormData.blog_category_new" placeholder="Enter the Blog category" type="text" class="form-control" required />
                  </div>
                </div><!-- Blog Category -->

                <div class="col-md-12 mb-3">
                  <span class="mb-3">
                    {{ cvBlogImageLabel }} <span class="primary-color font-size-16">*</span>
                  </span>

                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 100%; width: 100%; max-width:300px">
                        <img v-if="vmCoverPic" :src="vmCoverPic" alt="cover-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                        <i v-else @click="uploadClick()" class="fas fa-image d-flex justify-content-center align-items-center my-5" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <h5 class="p-image" @click="uploadClick()">
                          <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                          <input class="file-upload" type="file" accept="image/png,image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                        </h5>
                      </div>
                    </div>
                  </div>

                  <button type="button" class="p-2 rounded btn btn-primary border-0 mb-2 mr-2"  :disabled="cvCoverPicError || cvCoverPicLoading" @click="imageEdit('cover_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvCoverPicLoading" h5="Spinning"></b-spinner>
                    <span v-else>Save Profile Pic</span>
                  </button>

                  <button v-if="vmCoverPic" type="button" class="p-2 rounded btn btn-primary border-0 mb-2" :disabled="cvCoverPicError || cvCoverPicLoading"  @click="imageRemove('cover_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelCoverPicLoading" h5="Spinning">
                    </b-spinner>
                    <span v-else>Remove Profile Pic</span>
                  </button>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationblog_keywords">{{cvBlogKeywordsLabel}} <span class="primary-color font-size-16">*</span></label>
                  <input
                    v-model="vmblogFormData.blog_keywords"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationblog_short_desc">{{cvBlogShortDescLabel}} <span class="primary-color font-size-16">*</span></label>
                  <input
                    v-model="vmblogFormData.blog_short_desc"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>

                 <!-- Upload Documents -->
                 <div class="row col-12 w-100 mt-2">
                  <div class="col-md-12">
                    <label for="validationblog_attach">{{cvBlogAttachmentLabel}}</label>&nbsp;&nbsp;
                    <label for="excAttachmentFileInput" class="pointer primary-color">(Click here to upload)&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-upload"></i></label>
                    <input type="file" :accept="allowedExtensions" class="dontShow" id="excAttachmentFileInput" @change="onExtraCurricularAttatachmentChange($event, vmblogFormData, 'excAttachmentFileInput')">
                  </div>

                  <div class="col-md-12">
                    <template v-if="vmblogFormData.blog_attach && vmblogFormData.blog_attach.length">
                      <div v-for="(attachment, index) of vmblogFormData.blog_attach" :key="index">
                        <div class="attachmentCol mr-4">
                          <span class="pointer" @click="openFile(attachment.url)">{{ index+1 }}.&nbsp;&nbsp;&nbsp;&nbsp;{{ attachment.fileName ? attachment.fileName.split("/").pop() : attachment.url.split("/").pop() }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                          <img class="pointer" v-if="attachment.content" :src="pdfLogo" @click="openFile(attachment.url)">
                          <img v-else-if="attachment.fileName" :src="pdfLogo">
                          <img v-else alt="not found">
                          <span class="addIcon extraCloseIcon" @click="vmblogFormData.blog_attach.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div><!-- Upload Documents -->
              </div>

              <div class="form-group mt-2">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="blogEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />

    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { blogs } from "../../../FackApi/api/blog"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import { VueEditor } from "vue2-editor"
import CropImage from "../../../components/cropImage.vue"
import { Images } from "../../../FackApi/api/image"
import pdfLogo from "../../../assets_gide/img/logo/pdfLogo.png"

export default {
  name: "blogEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propblogObj: {
      type: Object,
      default: function () {
        return {
          "blog_id": "",
          "blog_title": "",
          "blog_desc": "",
          "blog_attach": []
        }
      }
    }
  },
  components: {
    VueEditor,
    CropImage
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      apiName: "blog_edit",
      cvCardTitle: "Edit Blog",
      cvSubmitBtn: "Update",
      cvBlogIdLabel: "Blog Id",
      cvBlogTitleLabel: "Blog Title",
      cvBlogVideoLabel: "Blog Video",
      cvBlogDescLabel: "Blog Description",
      cvBlogImageLabel: "Blog Image",
      cvBlogCateLabel: "Blog Category",
      cvBlogKeywordsLabel: "Blog Keywords",
      cvBlogShortDescLabel: "Blog Short Description",
      cvBlogAttachmentLabel: "Attach Documents to this blog",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Blog Updated",
      vmblogFormData: {},
      imageSize: "Size  1080 * 566",
      vmCoverPic: "",
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      isPhotoUploaded: false,
      blogFiltersList: [],
      cvCoverPicError: false,
      cvCoverPicLoading: false,
      cvDelCoverPicLoading: false,
      vmPBACoverPicImage: Object.assign({}, this.initImageData()),
      Update: 0,
      pdfLogo: pdfLogo,
      allowedExtensions: "application/pdf,image/png,image/jpg,image/jpeg,image/gif,image/webp,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
      cvImageSizeErrorMsg: "Image size must be less than 300 kb.",
      videoErrMsg: "",
      cvVideoError: false,
      cvVideoUrlMsg: "Only youtube url is supported",
      cvVideoUrlValidMsg: "Please only valid youtue video"
    }
  },
  async mounted () {
    socialvue.index()

    if (!userPermission(this, this.userData, this.apiName)) {
      window.location.reload()
      return
    }

    this.blogFilterList("blog_category")
    this.blogView()
  },
  methods: {
    /**
     * getVideoEmbedeUrl()
     */
    getVideoEmbedeUrl () {
      if (!this.vmblogFormData.blog_video) {
        return
      }
      try {
        let url = this.vmblogFormData.blog_video
        let youtubeUrlRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
        let videoIdRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

        // check url is whether youtube url or not
        let validUrl = youtubeUrlRegex.test(url)
        if (!validUrl) {
          this.cvVideoError = true
          this.videoErrMsg = this.cvVideoUrlMsg
          return false
        }

        let videoIdFound = url.match(videoIdRegex)
        if (!videoIdFound) {
          this.cvVideoError = true
          this.videoErrMsg = this.cvVideoUrlValidMsg
          return false
        }
        else {
          this.cvVideoError = false
          this.videoErrMsg = ""
          let videoId = videoIdFound[1]
          this.vmblogFormData.blog_video = `https://www.youtube.com/embed/${videoId}`
        }
      }
      catch (err) {
        console.error("Exception occurred at getVideoEmbedeUrl() and Exception:", err.message)
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmblogFormData) {
          if (!this.vmblogFormData[index] && index != "deleted") {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * blogView
     */
    async blogView () {
      try {
        let blogId = this.propblogObj.blog_id
        let blogViewResp = await blogs.blogView(this, blogId)

        if (blogViewResp && blogViewResp.resp_status) {
          this.vmblogFormData = blogViewResp.resp_data.data
          this.vmCoverPic = blogViewResp.resp_data.data.blog_img

          this.vmblogFormData.blog_attach = JSON.parse(this.vmblogFormData.blog_attach)
        }
      }
      catch (err) {
        console.error("Exception occurred at blogView() and Exception:", err.message)
      }
    },
    /**
     * blogEdit
     */
    async blogEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }

        if (this.vmblogFormData.blog_category_new) {
          this.vmblogFormData.blog_category = this.vmblogFormData.blog_category_new
        }

        this.cvLoadingStatus = true
        let blogAddResp = await blogs.blogEdit(this, this.vmblogFormData)

        await ApiResponse.responseMessageDisplay(this, blogAddResp)
        if (blogAddResp && !blogAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseblogEditModal", this.vmblogFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at blogEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /*
     * blogFilterList
     */
    async blogFilterList (filterName = null) {
      if (!filterName) {
        return
      }
      try {
        let blogFilterListResp = await blogs.blogFilterList(this, filterName)
        if (blogFilterListResp) {
          this.blogFiltersList[filterName] = blogFilterListResp.resp_data
          this.Update += 1
        }
      }
      catch (err) {
        console.error("Exception occurred at blog_filter_list_resp() and Exception:", err.message)
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 300 * 1024 // 300 KB in bytes
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.toastVariant = "danger"
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 540,
          "height": 283
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let blogImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image.push(
        {
          "img": img,
          "name": blogImg.name
        }
      )
      this.vmPBACoverPicImage.image_type[blogImg.name] = 3
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.propblogObj.blog_id,
        module_name: "BLOG",
        image: [],
        image_type: {}
      }
    },
    /**
     * uploadClick
     */
    uploadClick () {
      let id = document.getElementById("fileInput1")
      if (id) {
        id.click()
      }
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "cover_pic" && this.vmPBACoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        this.cvCoverPicLoading = true
        image = this.vmPBACoverPicImage
        const imageUpdateResp = await Images.imageProfileUpdate(this, image)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        let imageResp = imageUpdateResp.resp_data
        if (imageResp && imageResp.length > 0) {
          this.coverPicObj = imageResp[0]
        }
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        this.cvCoverPicLoading = false
      }
    },
    /**
     * imageRemove
     */
    async imageRemove (imgType) {
      if (imgType === "profile_pic") {
        this.cvDelProfilePicLoading = true
      }
      else {
        this.cvDelCoverPicLoading = true
      }
      let img = imgType === "profile_pic" ? 2 : 3
      const imageUpdateResp = await Images.imageDelete(this, this.propblogObj.blog_id, img)
      await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
      if (imageUpdateResp && !imageUpdateResp.resp_status) {
        return false
      }
      if (imgType === "profile_pic") {
        this.vmProfilePic = null
        this.cvDelProfilePicLoading = false
      }
      else {
        this.vmCoverPic = null
        this.cvDelCoverPicLoading = false
      }
    },
    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }

      window.open(url, "_blank")
    },
    /**
     * onExtraCurricularAttatachmentChange
     */
    onExtraCurricularAttatachmentChange (event, extraCurricular, inputId) {
      const fileList = event.target.files

      if (fileList && fileList.length) {
        for (let file of fileList) {
          // Allowed file types
          let allowedExtensions = this.allowedExtensions.split(",")

          let maxSize = 5 * 1024 * 1024 // 5MB limit

          if (!allowedExtensions.includes(file.type)) {
            this.toastMsg = "Invalid file type. Please upload a supported file."
            this.toastVariant = "danger"
            this.showToast = true
            continue
          }

          if (file.size > maxSize) {
            this.toastMsg = "File size exceeds the 5MB limit."
            this.toastVariant = "danger"
            this.showToast = true
            continue
          }

          // Read file and store in extraCurricular
          let reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = async (e) => {
            file.url = e.currentTarget.result
            file.fileName = file.name

            if (!extraCurricular.blog_attach) {
              extraCurricular.blog_attach = []
            }

            if (typeof extraCurricular.blog_attach === "string") {
              extraCurricular.blog_attach = JSON.parse(extraCurricular.blog_attach)
            }

            extraCurricular.blog_attach.unshift(file)

            // Reset input field
            const ele = document.getElementById(inputId)
            if (ele) {
              ele.value = ""
            }
          }
        }
      }
    }
  },
  watch: {
    "vmblogFormData.blog_video": function () {
      this.getVideoEmbedeUrl()
    }
  }
}
</script>
<style lang="scss">
  .attachmentCol {
    img {
      height: 32px;
      width: 32px;
      object-fit: contain;
      border: 2px solid aliceblue;
      margin-bottom: 6px;
    }
  }
  .extraCloseIcon {
    font-size: 18px;
    position: relative;
    top: -15px;
    cursor: pointer;
    z-index: 99;
    margin-left: -9px;
    color: #e5252c;
  }
</style>
